.footer__langs {
  @media (--phone) {
    align-items: flex-end;

    @media (--to-footer-langs-is-a-col) {
      align-self: flex-end;
      margin-bottom: 4.1rem;

      border-bottom: v(border-selago);
    }
  }

  @media (--footer-langs-is-a-col) {
    margin-top: -.6rem;
    padding: 0;
  }
}
