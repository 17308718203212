/* Generic */

.hero__icon-ctn {
  @media (--phone) {
    @include position(absolute, null null 0 50%);

    overflow: hidden;

    color: $han-purple-three;
  }
}

.hero__icon {
  @media (--phone) {
    position: absolute;

    width: 100%;
    height: auto;
  }
}

/* Specific */

// Calendar

.hero__icon-ctn--calendar {
  @media (--phone) {
    transform: translate3d(1%, 0%, 0);
    width: 81rem;
    height: 77.8rem;
  }
}

.hero__icon--calendar {
  @media (--phone) {
    top: 39.5%;
    left: 0;
  }
}

// Compass

.hero__icon-ctn--compass {
  @media (--phone) {
    transform: translate3d(-9%, 0%, 0);
    width: 92.7rem;
    height: 92.7rem;
  }
}

.hero__icon--compass {
  @media (--phone) {
    top: 27.5%;
    left: 0;

  }

  .compass-needle {
    --compass-animation: none;
  }
}

// “Valenciennes” word

.hero__icon-ctn--valenciennes {
  @media (--phone) {
    bottom: .1rem; // because of Safari partially showing the overflow
    // transform: translate3d(-50%, 0, 0) translate3d(1.973em, 0, 0); // commented while font-size issue (see below)

    width: 5.68310526em;
    height: 1em;

    /* Firefox issue: https://codepen.io/meduzen/pen/XWraLXJ
     *
     * So this won’t work 👇:
     */
    // font-size: 130rem;

    /* Instead, lower the font-size and scale it: */
    font-size: 13rem;
    transform: scale(10) translate3d(-50%, 0, 0) translate3d(1.973em, 0, 0);
    transform-origin: 0% 100%;
  }

  @media (--grid) {
    // font-size: 190rem;
    font-size: 19rem;
  }

  &::before {
    @media (--phone) {
      content: 'Valenciennes';
      @include position(absolute, .1em null null 0);

      pointer-events: none;

      line-height: 1;
      font-weight: 700;
    }
  }
}
