.services-ctn {
  @media (--phone) {
    display: grid;
  }

  .reverted {
    @media (--phone) {
      --services-light-bg: #{$han-purple-alt};
    }
  }
}
