$home-sections-w: (
  // 512px
  'home-1-col-fixed-pic-height': 32em,

  // 720px
  'home-2-col-narrow': 45em,

  // 1152px
  'home-2-col-fixed-pic-height': 72em,

  // 1280px (1160 + 2 * 60)
  'home-2-col': 80em,

  // 1460px
  'home-2-col-fixed-gap': 91.25em,
);

@include --w($home-sections-w);

.home__sections {
  @media (--phone) {
    padding: 0 v(x-space);
    display: grid;
  }

  @media (--home-2-col-narrow) {
    margin: 19.5rem v(x-space) 34.7rem;
    padding: 0;
    max-width: 116rem;

    grid-template-columns: 1fr 1fr;
    grid-gap: 14rem calc(50vw - 50%);
    align-items: center;
  }

  @media (--home-2-col) {
    margin-left: auto;
    margin-right: auto;

    grid-template-columns: 1fr 1.1fr;
  }

  @media (--home-2-col-fixed-gap) {
    column-gap: 15rem;
  }

  // Decorative picture
  &::before {
    @media (--phone) {
      content: '';
      order: 1;

      padding-top: 100%;
      display: block;

      background: darkgrey url('https://source.unsplash.com/GOD2mDNujuU/500x500') 50% 0% / cover no-repeat;
    }

    @media (--home-1-col-fixed-pic-height) and (--to-home-2-col-narrow) {
      padding-top: 40rem;
    }

    @media (--to-home-2-col-narrow) {
      margin: -5.6rem calc(var(--x-space) * -1) 0;
    }

    @media (--home-2-col-narrow) {
      border-radius: v(card-radius);
    }

    @media (--home-2-col-fixed-pic-height) {
      padding-top: 53rem;
    }
  }
}
