.f300 {
  font-weight: 300;
}

.f400 {
  font-weight: 400;
}

.f500 {
  font-weight: 500;
}
