.nav__rows {
  display: none;

  @include js {
    @media (--nav-collapsed) {
      display: initial;
    }
  }
}

.nav__row {
  --map-marker-shape: currentColor;
  --map-marker-shadow: none;

  .hero__row__map-marker {
    width: 1.5rem;
    height: auto;
  }
}
