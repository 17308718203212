@include --w-from(generic-large, '50em');

.section {
  margin: 5rem 0;
  padding: 0 v(x-space);

  font-size: 1.8rem;

  @media (--generic-large) {
    margin: 8rem auto;
    padding: 0;

    max-width: 85rem;

    font-size: 2.2rem;
  }
}

.generic__hero__title {

  @media (--generic-large) {
    margin-left: auto;
    margin-right: auto;

    max-width: 85rem;
  }
}

.generic__title {
  margin: 1em 0;
}
