.home__emphasis {
  @media (--phone) {
    margin: 0;

    font-size: 3.2rem;

    @media (--to-home-2-col-narrow) {
      padding-top: 8.3rem;
    }
  }

  @media (--home-2-col-fixed-pic-height) {
    font-size: 3.6rem;
    line-height: 1.3;
  }
}
