.service-highlight {
  @media (--phone) {
    padding: 0;

    position: relative;
    z-index: -1;

    display: flex;
    flex-direction: column;

    font-size: 3rem;
    line-height: 1.3;
  }

  @media (--services-highlights-zig-zag) {
    padding: 0 v(x-space);

    flex-direction: row;
    align-items: flex-start;

    font-size: 3.6rem;
  }

  @media (--services-highlights-grid) {
    padding: 0 calc(50% - 58rem);
  }

  @include even() {
    @media (--services-highlights-zig-zag) {
      justify-content: flex-end;
    }
  }
}

.service-highlight--before-pic {
  @media (--phone) {
    padding-bottom: 11.2rem;
  }
}

.service-highlight--with-pic {
  @media (--phone) {
    position: relative;
    z-index: 1;

    background: v(bg);
  }

  @media (--phone) and (--to-services-highlights-zig-zag) {
    @include ratio(100%);
  }

  @media (--services-highlights-zig-zag) {
    padding-top: 18rem;
  }

  &::before {
    @media (--phone) and (--to-services-highlights-zig-zag) {
      order: -2;

      background: darkgrey url('/img/checkpoint-700-466.jpg') center / cover no-repeat;
    }
  }
}

.service-highlight__inner {
  @media (--phone) {
    margin: 0;
    padding: 0 5.2rem 0 v(x-space);
  }

  @media (--services-highlights-zig-zag) {
    padding: 0;
    max-width: 51rem;
  }
}

.service-highlight__inner--longer {
  @media (--services-highlights-zig-zag) {
    max-width: 75rem;
  }
}
