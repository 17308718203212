.services-highlights {
  @media (--phone) {
    position: relative;
    grid-row-start: 3;
  }

  @media (--services-highlights-zig-zag) {
    z-index: 1;
  }
}

.services-highlights__list {
  @media (--phone) {
    counter-reset: services-highlights;
  }
}
