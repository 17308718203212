.price-table__title {
  @media (--phone) {
    margin-bottom: 2rem;
    padding: 0 v(pricing-cel-x-space);
  }

  @media (--prices-not-full-w) {
    padding: 0;
  }

  @media (--prices-2-col) {
    font-size: 2.2rem;
  }
}
