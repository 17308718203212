.langs {
  @media (--phone) {
    width: 100%;
    padding: .8em v(x-space);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-size: 2rem;
  }

  &:not(.langs--open) {
    @include js {
      .lang:not(.lang--current) {
        display: none;
      }
    }
  }
}

.langs__toggle {
  @include position(absolute, 0 null 0 0);
  width: 100%;

  text-align: right;
}

.langs__chevron {
  transform: rotate(90deg);
  transition: transform .3s $ease-in-out-quad;

  .langs--open & {
    transform: rotate(90deg) rotateY(180deg);
  }

  @include no-js {
    display: none;
  }
}

.lang__txt {
  @media (--phone) {
    display: block;
    padding: .375em 0;
  }
}

.lang--current {
  @media (--phone) {
    order: -1;
    position: relative;

    @include js {
      padding-right: 1.7rem;
    }
  }
}
