@keyframes icon-card-marquee {

  /* Safari 12 doesn’t allow `calc` in `@keyframes`, so this rule:
   * `transform: translate3d(0, calc((100% + var(--icons-grid-gap)) * -1), 0);`
   * has to become:
   * `transform: translate3d(0, -100%, 0) translate3d(0, -var(--icons-grid-gap), 0);`
   */
  0% {
    transform: translate3d(0, -100%, 0) translate3d(0, -var(--icons-grid-gap), 0);
  }

  100% {
    transform: translate3d(0, 100%, 0) translate3d(0, var(--icons-grid-gap), 0);
  }
}

$icon-grid-w: (
  'icons-grid-2-col': 23.875em, // 382px
  'icons-grid-3-col': 73.75em, // 1180px
);

@include --w($icon-grid-w);

.icons-grid {

  @media (--to-phone) {
    display: none;
  }

  @media (--phone) {
    --icons-grid-gap: 60px;
    --icon-card-w: 34.2rem;
    --icon-cols-nb: 1;

    width: calc(2 * var(--icons-grid-gap) + 3 * var(--icon-card-w));
    width: calc((var(--icon-cols-nb) - 1) * var(--icons-grid-gap) + var(--icon-cols-nb) * var(--icon-card-w));

    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow: hidden;
    pointer-events: none;

    color: $han-purple-four;
  }

  @media (--icons-grid-2-col) {
    --icon-cols-nb: 2;
  }

  @media (--icons-grid-3-col) {
    --icon-cols-nb: 3;
  }
}

.icons-grid__column {
  flex: 0 0 v(icon-card-w);

  display: grid;
  grid-gap: v(icons-grid-gap);

  &:nth-of-type(3n-1) {
    --icon-card-marquee-direction: reverse;
  }

  @include all-but(1) {
    margin-left: v(icons-grid-gap);

    @media (--to-icons-grid-2-col) {
      display: none;
    }
  }

  @include after-first(2) {
    @media (--to-icons-grid-3-col) {
      display: none;
    }
  }
}

.icons-grid__group {
  display: grid;
  grid-auto-rows: 47.8rem;
  grid-gap: v(icons-grid-gap);

  animation: icon-card-marquee 60s linear v(icon-card-marquee-direction, normal) infinite;
}

.icon-card {
  @media (--phone) {
    position: relative;
    height: 47.8rem;

    background: $han-purple-three;
    border-radius: v(card-radius);
  }
}

.icons-grid__icon {
  @media (--phone) {
    transform: scale(2.75);
  }
}
