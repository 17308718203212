.header {
  @media (--phone) {
    position: relative;
    z-index: 1;
  }

  @media (--nav-compact) {
    padding: v(x-space);

    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.8rem;
    font-weight: 500;
  }

  @media (--nav-expanded) {
    margin: 0 auto;
    padding: 5.6rem 0;
    max-width: 116rem;
  }
}

.header__inner {
  @media (--phone) {
    display: inline-flex;
  }

  @media (--phone) and (--nav-collapsed) {
    position: relative;
    z-index: 11;

    width: 100%;

    justify-content: space-between;
    align-items: flex-start;
  }

  @media (--nav-compact) {
    align-items: center;
  }
}
