.header__trucketape {
  @media (--nav-expanded) {
    font-size: inherit;
  }
}

.header__logo {
  @media (--nav-collapsed) {
    padding: v(x-space);
    display: inline-flex;
  }
}

.trucketape-logo {
  @media (--nav-collapsed) {
    width: 5rem;
    height: auto;

    background: v(bg);
  }
}
