.header__valenciennes {
  margin: 0 1.8rem 0 5.5rem;

  @media (--before-nav-expanded) {
    display: none;
  }
}

.geo {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1em;

  font-size: 1.4rem;

  @media (--before-nav-expanded) {
    display: none;
  }
}

.compass-needle {
  --compass-angle: 45deg;
  --compass-animation: rotate-compass 26s infinite 4s;
}

.geo__coordinates {
  display: flex;
  flex-direction: column;
}

.geo__separator {
  @media (--nav-expanded) {
    display: none;
  }
}
