/* Different card layout when the viewport width grows. */

.service-card--big {
  @media (--card-grid) {
    padding: 3rem;

    align-items: flex-start;

    .service-card__icon-ctn,
    .service-card__icon {
      order: 1;
      position: static;

      margin-top: auto;

      width: initial;
      flex: 0 0 auto;
    }

    .service-card__title {
      margin-bottom: 3rem;

      font-size: 2.5rem;
    }

    .service-card__txt {
      margin-bottom: 3rem;

      font-size: 1.8rem;
    }

    .service-card__list {
      grid-template-columns: auto auto;
      column-gap: 3rem;
    }
  }

  @media (--grid) {
    .service-card__icon {
      transform: scale(1.8);
      transform-origin: 0 100%;
      transition: transform .3s $ease-out-back;
    }
  }
}
