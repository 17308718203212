.home__fork-knife {
  @media (--phone) {
    position: relative;
    z-index: 0;

    @include ratio(100%);
    width: 100%;
    max-width: 17.5rem;
    height: auto;

    border-radius: v(card-radius);

    @media (--to-home-2-col-narrow) {
      margin-top: 9.7rem;
    }
  }

  @media (--home-2-col-narrow) {
    grid-row: 2/3;
    justify-self: flex-end;

    width: calc(100% - 5.5rem);
    max-width: 38.8rem;

    &::before {
      padding-top: 105.15%;
    }
  }
}

.home__lock {
  order: 2;
  justify-self: flex-end;
  transform: translate3d(50%, 0, 0);

  width: 16rem;
  height: 16rem;

  border-radius: v(card-radius);

  @media (--to-home-2-col-narrow) {
    display: none;
  }
}
