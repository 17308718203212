.service-card {
  @media (--phone) {
    padding: 0 2rem 2.1rem;
    min-height: 9rem;

    position: relative;

    display: inline-flex;
    flex-direction: column;

    background: v(services-light-bg);
    border-radius: v(card-radius);
  }

  @media (--card-grid) {
    min-height: 12rem;
  }
}

.service-card__icon-ctn {
  @media (--phone) {
    @include position(absolute, 0 null 0 0);
    width: 9.8rem;
  }
}

.service-card__icon {
  @media (--card-grid) {
    transform: scale(1.2);
  }
}

.service-card__title {
  @media (--phone) {
    margin-top: 2.1rem;
    font-size: 2.2rem;
  }
}
