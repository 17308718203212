.nav {
  @media (--nav-collapsed) {
    padding: 2.6rem 0;

    @include js {
      @include position(fixed, 0 0 null 0)
      z-index: 10;

      padding: 13.6rem 0 0;
      height: 100%;

      display: grid;
      grid-template-rows: 1fr auto;

      overflow-y: scroll;
      -webkit-overflow-scrolling: touch; // Safari ignore overflow-y: scroll; without this. Maybe not needed anymore on iOS 13 (https://webkit.org/blog/9674/new-webkit-features-in-safari-13/)

      background: v(bg);

      transition: transform .3s $ease-in-out-quad;

      &:not(.nav--open) {
        transform: translate3d(100%, 0, 0);
      }
    }
  }
}
