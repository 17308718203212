$hero-row-w: (
  'hero-row-expansion-start': 34em, // 544px
  'hero-row-expansion-half': 45em, // 720px
  'hero-row-expanded': 50em, // 800px
  'hero-row-fixed-grid': 80em, // 1280px (1160 + 2 * 60)
);

@include --w($hero-row-w);

.hero__rows {
  @media (--phone) {
    position: relative;

    font-size: 1.8rem;
  }

  @media (--hero-row-fixed-grid) {
    font-size: 2.2rem;
  }
}

.hero__row {
  --hero-row-bg: hsl(263, 100%, var(--hero-row-bg-l, 38%));

  @media (--phone) {
    background: v(hero-row-bg);
  }

  &:nth-of-type(2) {
    @media (--phone) {
      --hero-row-bg-l: 35%;
    }
  }

  &:nth-of-type(3) {
    @media (--phone) {
      --hero-row-bg-l: 34%;
    }
  }
}

.hero__row__link {
  @media (--phone) {
    padding: 1.1em var(--x-space);

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (--grid) {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 116rem;
  }
}

.hero__row__content {
  @media(--phone) {
    display: grid;
    grid-template-columns: 4rem 1fr;
  }

  @media (--hero-row-expanded) {
    display: grid;
    grid-template-columns: 5rem 1fr;
  }
}

.hero__row__map-marker {
  @media (--hero-row-expanded) {
    width: 2.7rem;
    height: auto;
  }
}

.hero__row__hidden--expansion-not-started {
  @media (--to-hero-row-expansion-start) {
    display: none;
  }
}

.hero__row__hidden--expansion-started {
  @media (--hero-row-expansion-start) {
    display: none;
  }
}

.hero__row__hidden--to-expansion-half {
  @media (--to-hero-row-expansion-half) {
    display: none;
  }
}

.hero__row__hidden--expanded {
  @media (--hero-row-expanded) {
    display: none;
  }
}

.hero__row__chevron {
  @media (--phone) {
    margin-left: 1.7rem;
  }

  @media (--hero-row-fixed-grid) {
    width: .8rem;
    height: auto;
  }
}
