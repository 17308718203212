.hero {
  @media (--phone) {
    position: relative;
    padding-top: 9rem;
  }

  @media (--grid) {
    padding-top: 20rem;
  }

  > * {
    @media (--phone) {
      position: relative;
    }
  }
}
