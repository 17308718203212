.pricing-highlight {
  @media (--phone) {
    grid-area: highlights;
  }
}

.pricing-highlight-grid {
  @media (--card-grid) {
    grid-gap: .7rem;
  }
}

.pricing-highlight-card__title {
  @media (--phone) {
    font-size: 2rem;
  }
}
