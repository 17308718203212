.service_highlight__locks {
  @media (--phone) {
    margin: 1.1rem 0 .7rem;

    display: grid;
    grid-gap: .1rem;
    grid-template-columns: repeat(4, 3.8rem);
  }

  @media (--services-highlights-zig-zag) {
    margin: 0 .2em;

    display: inline-grid;
  }
}

.service_highlight__lock {
  @media (--phone) {
    width: 3.7rem;
    height: 3.7rem;

    background: v(services-light-bg);
    border-radius: 50%;
  }
}
