.security-grid {
  @media (--phone) {
    position: relative;
    display: grid;

    @media (--to-card-grid) {
      padding: 0 v(x-space) 2rem;

      grid-gap: v(x-space);
      grid-auto-flow: column;
      grid-auto-columns: 27.5rem;
      grid-template-rows: minmax(33.8rem, 30vh);

      -webkit-overflow-scrolling: touch; // iOS free scroll (momentum). Maybe not needed anymore on iOS 13 (https://webkit.org/blog/9674/new-webkit-features-in-safari-13/)
      overflow-x: scroll;

      scroll-snap-type: x mandatory;

      scrollbar-width: thin;
      scrollbar-color: v(services-light-bg);
    }
  }

  @media (--card-grid) {
    > :nth-child(1) {
      grid-column-start: 2;
    }

    > :nth-child(3) {
      grid-column-start: 1;
    }

    > :nth-child(4) {
      grid-column-start: -3;
    }

    > :nth-child(6) {
      grid-column-start: 2;
    }
  }

  &::-webkit-scrollbar {
    @media (--phone) and (--to-card-grid) {
      height: .4rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    @media (--phone) and (--to-card-grid) {
      background: v(services-light-bg);
    }
  }

  // Horizontal scroll: far right spacer hack.
  &::after {
    @media (--phone) and (--to-card-grid) {
      content: '';
      width: .01rem;
    }
  }
}
