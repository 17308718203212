.nav__item--booking {
  @media (--nav-compact) {
    order: 1;
    margin-left: 2.5rem;

    // Prevent _current page_ underline
    .nav__link__inner {
      &::before {
        display: none;
      }
    }
  }
}

.nav__link--booking {
  @media (--nav-collapsed) {
    height: auto;

    line-height: inherit;

    background: none;
  }
}

.nav__booking__content {
  @media (--nav-collapsed) {
    font-weight: 300;
  }
}
