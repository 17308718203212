.footer__sections {
  @media (--phone) {
    width: 100%;

    @media (--to-footer-langs-is-a-col) {
      padding: 0 v(x-space);
    }
  }

  @media (--footer-3-col) {
    grid-row-start: 1;

    display: grid;
    grid-template-columns: 1.9fr 1fr auto;
  }
}

.footer__section {
  @media (--phone-to-grid) {

    @media (--to-footer-3-col) {
      margin-top: 3.1rem;
    }
  }
}

.footer__title {
  @media (--phone) {
    margin-bottom: .5em;

    font-size: 2rem;
  }

  @media (--footer-3-col) {
    margin-top: .5rem;

    font-size: 2.2rem;
  }
}

.footer__link {
  @media (--phone) {
    display: block;
    padding: .375em 0;
  }

  @media (--footer-3-col) {
    padding: 0.22em 0;

    font-size: 1.8rem;
  }
}
