@import 'header';
@import 'footer';

.wrapper {
  @media (--phone) {
    overflow-x: hidden;
  }

  .nav-is-open & {
    @media (--phone) and (--nav-collapsed) {
      max-height: 100%;
      overflow-y: hidden;
    }
  }
}

.nav-is-open {
  body {
    @media (--phone) and (--nav-collapsed) {
      position: fixed;
      overflow: hidden;
    }
  }
}
