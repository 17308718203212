.services {
  @media (--phone) {
    position: relative;

    background: v(bg);
  }

  &::after {
    @media (--phone) and (--to-card-grid) {
      content: '';
      margin: 8.3rem 0 7.3rem;
      padding-top: 100%;
      display: block;

      background: darkgrey url('/img/checkpoint-700-466.jpg') center / cover no-repeat;
    }
  }
}
