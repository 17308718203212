.services-grid {

  @media (--card-grid) {

    // Items

    > :nth-child(1) {
      grid-column-start: 2;
    }

    > :nth-child(2) {
      grid-column-start: 1;
    }

    > :nth-child(4) {
      grid-column-start: 2;
    }

    > :nth-child(6) {
      grid-column: 3 / span 2;
    }

    > :nth-child(7) {
      grid-column-start: 2;
    }

    // Pictures

    &::before,
    &::after {
      content: '';
      border-radius: v(card-radius);
      background: center / cover no-repeat;
    }

    &::before {
      grid-row-start: 2;
      grid-column: -3 / -1;

      background-image: url('/img/checkpoint-700-466.jpg');
    }

    &::after {
      grid-row-start: 4;
      grid-column: 1 / -3;

      background-image: url('https://source.unsplash.com/GOD2mDNujuU/500x500');
    }
  }
}
