.payment {
  @media (--phone) {
    grid-area: payment;
    padding: 3rem 0 .6rem;

    overflow: hidden;
  }

  @media (--grid) {
    margin-bottom: 11.2rem;
    padding: 4.8rem 0;

    border-bottom: v(border-selago);
  }
}

.payment__list {
  @media (--phone) {
    padding: 0 v(x-space) 1rem;
    display: grid;
    grid-gap: 5.6rem;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;

    overflow-x: scroll;

    -webkit-overflow-scrolling: touch; // iOS free scroll (momentum). Maybe not needed anymore on iOS 13 (https://webkit.org/blog/9674/new-webkit-features-in-safari-13/)

    scrollbar-width: thin;
    scrollbar-color: v(services-light-bg);
  }

  @media (--grid) {
    padding-bottom: 0 v(x-space) 0;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    @media (--phone) {
      height: .4rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    @media (--phone) {
      background: v(services-light-bg);
    }
  }

  // Horizontal scroll: far right spacer hack.
  &::after {
    @media (--phone) {
      // content: '';
      padding: .1rem;
      width: 1.8rem;
    }
  }
}

.payment__item {
  @media (--phone) {
    --payment-cx: 0.75;

    width: calc(var(--payment-w, 0) * var(--payment-cx));
    height: calc(var(--payment-h, 0) * var(--payment-cx));
    position: relative;

    font-size: 0;
  }

  @media (--grid) {
    --payment-cx: 1;
  }

  &::after {
    content: '';

    @include position(absolute, 0 0 0 0);
    background: v(bg) v(payment-bg) 0 0 / contain no-repeat;
  }
}

.payment__item--axxes {
  --payment-w: 7.5rem;
  --payment-h: 3.5rem;
  --payment-bg: url('/img/payment/axxes.png');
}

.payment__item--cb {
  --payment-w: 4.7rem;
  --payment-h: 3.3rem;
  --payment-bg: url('/img/payment/cb.png');
}

.payment__item--dkv {
  --payment-w: 5.2rem;
  --payment-h: 3.9rem;
  --payment-bg: url('/img/payment/dkv.png');
}

.payment__item--eurotoll {
  --payment-w: 10.6rem;
  --payment-h: 3.4rem;
  --payment-bg: url('/img/payment/eurotoll.png');
}

.payment__item--mastercard {
  --payment-w: 7.8666rem; // 5.2rem + var(--x-space) * 1.333
  --payment-h: 4rem;
  --payment-bg: url('/img/payment/mastercard.png');
}

.payment__item--telebadges {
  --payment-w: 3.6rem;
  --payment-h: 3.7rem;
  --payment-bg: url('/img/payment/telebadges.png');
}

.payment__item--telepass {
  --payment-w: 10.1rem;
  --payment-h: 1.9rem;
  --payment-bg: url('/img/payment/telepass.png');
}

.payment__item--total {
  --payment-w: 4.3rem;
  --payment-h: 5.5rem;
  --payment-bg: url('/img/payment/total.png');
}

.payment__item--visa {
  --payment-w: 5.7rem;
  --payment-h: 2.4rem;
  --payment-bg: url('/img/payment/visa.png');
}
