.services-list {
  @media (--phone) {
    margin-top: 5.5rem;
    display: grid;

    @media (--to-card-grid) {
      padding: 0 v(x-space);
      grid-gap: 1rem;
    }
  }
}
