/* Converted from WOFF to WOFF2 using https://transfonter.org,
 * without vertical metrics changes.
 */

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-extralight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-light-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-regular-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Vinci Sans';
  src: url('/fonts/vinci-sans/vinci-sans-black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
