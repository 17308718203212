.price-table {
  @media (--phone) {
    margin-bottom: 6rem;
    width: 100%;

    text-align: left;

    border-collapse: collapse;
  }

  @media (--prices-2-col) {
    margin-bottom: 8rem;
  }

  tr {
    @media (--phone) {
      display: grid;
      grid-template-columns: minmax(11.9rem, 1fr) 11rem 9rem;
    }

    @media (--grid) {
      grid-template-columns: 1fr .6fr .9fr;
    }
  }

  th,
  td {
    @media (--phone) {
      padding: v(pricing-cel-y-space, 1.4rem) v(pricing-cel-x-space);

      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;

      font-size: 1.8rem;
      font-weight: 400;
    }
  }

  td {
    @media (--phone) {
      border-left: v(border-selago);
    }
  }
}

.price-table__body {
  @media (--phone) {
    border: v(border-selago);
  }
}

.pricing-table__duration-mention {
  @media (--phone) {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.pricing-table__hour {
  @media (--phone) {
    transform: translate3d(0, -.1rem, 0);

    margin: 0 .4rem;
    display: inline-block;

    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.pricing-table__highlight {
  --pricing-cel-y-space: 2.9rem;
}

.pricing-table__highlight--big {
  --pricing-cel-y-space: 6rem;
}
