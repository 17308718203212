.security {
  @media (--phone) {
    padding-top: 5rem;
    padding-bottom: 1rem;

    overflow: hidden;
  }

  @media (--services-highlights-zig-zag) {
    padding-top: 9.8rem;
    padding-bottom: 13rem;
  }
}
