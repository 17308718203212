.security-card {
  @media (--phone) {
    padding: 5rem 3rem;

    scroll-snap-align: center;

    font-size: 2.2rem;

    background: v(services-light-bg);
    border-radius: v(card-radius);
  }

  @media (--card-grid) {
    font-size: 2.4rem;
  }
}
