.hero__title {
  @media (--phone) {
    margin: 0;
    padding: 0 v(x-space) 3.5rem;

    font-size: 4.2rem;
  }

  @media (--grid) {
    padding: 0 0 6.5rem;

    max-width: 116rem;
    margin-left: auto;
    margin-right: auto;

    font-size: 5rem;
    line-height: 1.4;
  }
}

.hero__title--more-space-after {
  @media (--grid) {
    padding-bottom: 11.4rem;
  }
}

.hero__title--limited-width-m {
  @media (--grid) {
    padding-right: 45rem;
  }
}

.hero__title--limited-width-l {
  @media (--grid) {
    padding-right: 25rem;
  }
}
