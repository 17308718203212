$home-sections-w: (
  // 840px
  'contact-2-col': 52.5em,

  // 928px
  'contact-fixed-gap': 58em,

  // 1280px (1160 + 2 * 60)
  'contact-fixed-grid': 80em,
);

@include --w($home-sections-w);

.contact__hero__rows {
  @media (--phone) {
    margin-bottom: 3.5rem;
  }

  @media (--contact-2-col) {
    margin-bottom: 11.7rem;
    grid-column: 1 / -1;
  }
}

.contact__details {
  margin: 0 v(x-space);
  padding-bottom: 2.1rem;

  @media (--contact-2-col) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: v(x-space);
  }

  @media (--contact-fixed-gap) {
    column-gap: 10rem;
  }

  @media (--contact-fixed-grid) {
    margin: 0 auto 30rem;
    max-width: 116rem;

    grid-template-columns: 1fr 1.1fr;
  }
}

.contact__emphasis {
  @media (--phone) {
    margin: 3.6rem 0 0;

    font-size: 3.2rem;
  }

  @media (--contact-2-col) {
    margin-top: .6rem;

    font-size: 3.6rem;
    line-height: 1.3;
  }
}

.contact__list {
  @media (--phone) {
    font-size: 1.8rem;
  }

  @media (--contact-2-col) {
    order: 1;

    font-size: 2.2rem;
  }
}

.contact__item {
  @media (--phone) {
    padding: 1.6rem 0;

    display: grid;

    /* the variable is calculted in the HTML, ensuring the correct length */
    grid-template-columns: calc(#{v(contact-list-heading-length, 5)} * 1ch + 1.2ch) 1fr;

    overflow-x: scroll;
    white-space: nowrap;
  }

  @media (--contact-2-col) {
    padding: 1.3rem 0;
  }

  @include all-but(1) {
    @media (--phone) and (--to-tablet-10) {
      border-top: v(border-selago);
    }
  }
}
