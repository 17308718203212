.booking {
  @media (--phone) {
    height: 5rem;
    padding: 0 4.2rem;

    font-size: 1.8rem;
    line-height: 1;

    background: v(accent);
    border-radius: 6rem;
  }
}

.booking--current {
  @media (--phone) {
    background: $purple-two;
  }
}

.booking__content {
  @media (--phone) {
    position: relative;
    top: -0.05em;
  }
}

.booking__icon {
  margin-right: 1rem;
}
