.service-card--text {
  @media (--phone) {
    padding-left: 9.8rem;
    justify-content: center;
  }

  @media (--grid) {
    padding-left: 10.9rem;
  }
}

.service-card__txt {
  @media (--phone) {
    margin: .3rem 0 0;
  }
}
