.service-card--list {
  @media (--phone) {
    padding-top: 3rem;
    align-items: flex-start;
  }

  &.service-card--big {
    @media (--card-grid) {
      grid-column-start: span 2;
    }
  }
}

.service-card__list {
  @media (--phone) {
    margin-top: 1rem;

    display: grid;
    grid-gap: .8rem;
  }
}
