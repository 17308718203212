.home__link {
  @media (--phone) {
    margin: 2.3rem 0 0;
    display: block;

    font-size: 2.6rem;
  }

  @media (--home-2-col) {
    margin-top: 4rem;
  }
}

.home__link__chevron {
  @media (--phone) {
    margin-left: .9rem;
    width: .8rem;
    height: auto;

    display: inline-block;
  }
}
