.prices {
  @media (--phone) {
    --pricing-cel-x-space: 1.2rem;

    width: 100%;

    grid-area: prices;
  }

  @media (--prices-not-full-w) {
    --pricing-cel-x-space: 1.5rem;
  }

  @media (--prices-not-full-w-to-prices-2-col) {
    width: calc(100% - 2 * var(--x-space));
  }

  @media (--to-prices-2-col) {
    margin: 6rem auto 0;
  }

  @media (--prices-2-col) {
    margin-top: 5rem;
    max-width: 53rem;
  }
}
