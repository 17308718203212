.footer__to-top {
  @media (--phone) {
    position: absolute;
    top: -7rem;
    right: v(x-space);

    width: 5rem;
    height: 5rem;

    background: v(primary);
    border-radius: 50%;
  }
}

.footer__to-top__svg {
  @media (--phone) {
    fill: v(bg);
  }
}
