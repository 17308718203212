.nav__list {
  @media (--nav-collapsed) {
    @include js {
      padding-bottom: 3.3rem;
    }
  }

  @media (--nav-compact) {
    display: flex;
    align-items: center;
  }
}

.nav__item {
  @media (--nav-collapsed) {
    margin: .9em 0;
  }
}

.nav__item--current {
  .nav__link__inner {
    &::before {
      content: '';
    }
  }
}

.nav__icon {
  @media (--nav-collapsed) {
    display: none;
  }
}

.nav__link {
  @media (--nav-collapsed) {
    display: block;
    padding: 0 v(x-space);

    font-size: 3.2rem;
  }

  &:not(.booking) {
    @media (--nav-compact) {
      padding: 1.2rem 1.5rem;
    }
  }
}

.nav__link__inner {
  position: relative;

  // Current page underline
  &::before {
    @include position(absolute, null null 0 0);
    width: 100%;
    height: .1rem;

    background: v(primary);

    @media (--nav-compact) {
      bottom: -.4rem;
    }
  }
}
