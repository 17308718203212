.pricing__hero {
  @media (--phone) {
    grid-area: hero;
  }
}

.pricing__hero__rows {
  @media (--hero-row-expanded) {
    display: flex;
  }
}

.pricing__hero__row {
  @media (--hero-row-expanded) {
    background: none;
  }

  &:first-of-type {
    @media (--hero-row-fixed-grid) {
      margin-left: calc(50% - 58rem);
    }
  }

  @include all-but(1) {
    @media (--hero-row-expanded) {
      margin-left: calc(10.3rem - 2 * var(--x-space));
    }

    @media (--hero-row-fixed-grid) {
      margin-left: 10.3rem;
    }
  }
}
