$pricing-w: (
  'prices-not-full-w': 28em, // 448px
  'prices-2-col': 45em, // 720px
);

@include --w($pricing-w);

.pricing-ctn {
  @media (--phone) {
    display: grid;
    grid-template-areas:
      "hero"
      "payment"
      "highlights"
      "prices";
  }

  @media (--prices-2-col) {
    margin-bottom: 31.6rem;

    grid-template-areas:
      "hero hero hero hero hero"
      "payment payment payment payment payment"
      ". prices . highlights .";

    grid-template-columns: v(x-space) 1fr v(x-space) 1fr v(x-space);
  }

  @media (--grid) {
    grid-template-columns: 1fr minmax(auto, 53rem) 10rem minmax(auto, 53rem) 1fr; // 53 * 2 + 10 = 116rem;
  }
}
