.footer {
  @media (--phone) {
    position: relative;
    margin-top: 20.2rem;
    padding-bottom: 11.1rem;

    background: v(bg);
    border-top: v(border-selago);
  }

  @media (--footer-langs-is-a-col) {
    padding-bottom: 6rem;
    padding-left: v(x-space);
    padding-right: v(x-space);
  }

  &::before {
    @media (--phone) {
      content: 'Valenciennes';
      @include position(absolute, -.78em null null 0);
      z-index: -1;
      transform: translate3d(-.4em, 0, 0);

      pointer-events: none;

      font-size: 28rem;
      line-height: 1;
      font-weight: 500;

      color: $ghost-white;
    }

    @media (--footer-3-col) {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.footer__inner {
  display: flex;
  flex-direction: column;

  @media (--to-footer-3-col) {
    align-items: flex-end;
  }

  @media (--footer-3-col) {
    margin: 0 auto;
    max-width: 116rem;
  }

  @media (--footer-langs-is-a-col) {
    padding-top: 7.3rem;

    display: grid;
    grid-template-columns: 1fr 20rem;
  }
}
