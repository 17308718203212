.nav__toggle {
  padding: var(--x-space);

  display: none;

  @include js {
    @media (--nav-collapsed) {
      display: flex;
    }
  }
}

.nav__toggle__icon--menu {
  .nav-is-open & {
    display: none;
  }
}

.nav__toggle__icon--plus {
  transform: translate3d(-.2rem, -.3rem, 0) rotate(45deg);

  html:not(.nav-is-open) & {
    display: none;
  }
}
