$card-grid-w: (
  'card-grid': 45em, // 720px
);

@include --w($card-grid-w);

.cards-grid {
  @media (--card-grid) {
    padding-left: v(x-space);
    padding-right: v(x-space);

    grid-auto-flow: row;
    grid-auto-rows: 33.8rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 2rem;
  }

  @media (--grid) {
    margin: 0 auto;
    padding: 0;
    max-width: 116rem;
  }
}
