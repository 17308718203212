@custom-media --services-highlights-counter-right-compact (--phone) and (width < 23.5em);
@include --w-from('services-highlights-counter-right', 23.5em);

.service-highlight {
  &::after {
    @media (--phone) {
      content: counter(services-highlights, decimal-leading-zero);
      counter-increment: services-highlights;

      margin: 1.1rem 0 -10.9rem;

      order: -1;
      position: relative;
      z-index: -1;

      font-family: 'Chivo';
      font-size: 15rem;
      font-weight: 700;

      color: v(services-light-bg);
    }

    @media (--services-highlights-counter-right-compact) {
      left: 17rem;
      text-align: left;
    }

    @media (--services-highlights-counter-right) and (--to-services-highlights-zig-zag) {
      right: v(x-space);
      text-align: right;
    }

    @media (--services-highlights-zig-zag) {
      margin: -1ch -1.5ch 1ch 0;
    }
  }
}
