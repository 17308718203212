.footer__esporg {
  @media (--to-footer-3-col) {
    display: none;
  }

  @media (--footer-3-col) {
    grid-column-start: 2;
    justify-self: flex-end;

    width: 8.2rem;
  }

  @media (--to-footer-langs-is-a-col) {
    align-self: flex-end;
    margin-right: v(x-space);
  }
}

