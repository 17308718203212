.reverted {
  --bg: #{$han-purple};
  --primary: #{$white};

  background: v(bg);
  color: v(primary);

  ::selection {
    color: v(primary);
  }
}

.reverted--accent {
  --bg: var(--accent);
}

.accent {
  color: v(accent);
}

.pale-text {
  color: v(pale-text);
}
